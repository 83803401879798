import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import App from './App';

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://30f667017fd24779bfa940a51c2ec9fd@o511280.ingest.sentry.io/5908636',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
