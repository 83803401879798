import React from 'react';

function App(): JSX.Element {
  return (
    <div>
      <h1>Hello SMRT:MBO!</h1>
      <button
        onClick={() => {
          throw new Error('Sentry test');
        }}
        type="button"
      >
        Raise Error
      </button>
    </div>
  );
}

export default App;
